import './scss/pixel.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is imported
import 'assets/css/pixel.css';
import 'scss/pixel.scss';
import 'vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'vendor/@glidejs/glide/dist/css/glide.core.min.css';
import 'vendor/@glidejs/glide/dist/css/glide.theme.min.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);