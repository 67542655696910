import React from 'react';
import Button from './Button';

const Modal = (props) => {

  return (<>
    <div class={props.showModal ? "modal fade show" : "modal fade"} id="modal-default" tabindex="-1" role="dialog" aria-labelledby="modal-default" aria-modal="true" style={props.showModal ? {display: "block"} : {}}>
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-primary text-white">
          <div class="modal-body">
            <div class="py-3 text-center">
              <span class="modal-icon display-1-lg">
                <span class="far fa-image"></span>
              </span>
              <h2 class="h4 modal-title my-3">Important message!</h2>
              <p>There is an image file smaller than 512 pixels in size.</p>
              <p>The result may be inaccurate.</p>
              <p class="px-lg-5">Would you like to proceed?</p>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <Button variant="secondary" onClick={() => props.handleAgree()}>Proceed</Button>
              <Button class="btn btn-white ms-auto" data-bs-dismiss="modal" onClick={() => props.handleCancel()}>Close</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default Modal;