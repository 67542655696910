import styled from 'styled-components';

const UploadStyle = styled.div`

  display: block;
  width: ${(props) => (props.page === "create" ? '100%' : 'auto')} !important;
  height: ${(props) => (props.page === "create" ? '100%' : 'auto')} !important;

  .ant-upload-drag {
    border: none;
  }
  .ant-upload-btn  {
    margin: auto !important;
  }
  .upload {
    height: 100% !important;
    background: rgba(58, 170, 58, 0.1);
    border: 2px dashed #3AAA3A;
    border-radius: 16px;
  }
  .customSize {
    display: block;
    height: 100% !important;
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    .customSize {
      aspect-ratio: auto !important; /* 'none' is not a valid value for aspect-ratio, use 'auto' instead */
    }
  }
`;

export default UploadStyle;