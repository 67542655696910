import React from 'react';
import Button from 'components/widget/Button';
import HeaderStyle from 'scss/custom/components/layout/Header.style';
import { Link, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { store } from '../../redux/store';
import { clearUser } from '../../redux/slices/auth';
import { showNotification } from 'utils/notification';

const Header = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const handleLogout = () => {
    store.dispatch(clearUser());
    showNotification("success", "Log out successful.");
  };

  return (
  <HeaderStyle>
    <nav id="navbar-main" aria-label="Primary navigation" class="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-dark bg-primary">
    <div class="container position-relative">
      <Link to="/">
        <a class="navbar-brand me-lg-5">
          <img class="navbar-brand-dark" src="/logo/logo.png" alt="Logo light" style={{width: '62px', height: '50px'}} />
          <img class="navbar-brand-light" src="../../assets/img/brand/dark.svg" alt="Logo dark" />
        </a>
      </Link>
      <div class="navbar-collapse collapse me-auto" id="navbar_global">
        <div class="navbar-collapse-header">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a href="../../index.html">
                <img src="../../assets/img/brand/dark.svg" alt="Themesberg logo" />
              </a>
            </div>
            <div class="col-6 collapse-close">
              <a href="#navbar_global" class="fas fa-times" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" title="close" aria-label="Toggle navigation"></a>
            </div>
          </div>
        </div>
        <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
          <li class="nav-item">
            <Link to="/create">
              <span class="nav-link">Create Listing</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/pricing">
              <span class="nav-link">Pricing</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/faq">
              <span class="nav-link">FAQ</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/contactus">
              <span class="nav-link">Contact Us</span>
            </Link>
          </li>
        </ul>
      </div>

      <div class="d-flex align-items-center">
        {
          user ?
            <div class="nav-item dropdown">
              <span class="dropdown-toggle btn btn-outline-gray-100 d-none d-lg-inline me-md-3" id="dashboardDropdown" data-bs-toggle="dropdown" >
                <i class="fas fa-user"></i>
              </span>
              <div class="dropdown-menu dropdown-megamenu-sm px-0 py-2 p-lg-4" aria-labelledby="dashboardDropdown" data-bs-popper="none">
                <ul class="list-style-none">
                 <li class="mb-2 megamenu-item">
                    <span class="megamenu-link">{user?.email}</span>
                  </li>
                  <li class="mb-2 megamenu-item">
                    <span class="megamenu-link">Credits: {user?.fcredits} + {user?.pcredits}</span>
                  </li>
                  <li class="megamenu-item">
                    <a onClick={() => handleLogout()}>Log out</a>
                  </li>
                </ul>
              </div>
            </div>
          :
          <>
            <Link to="/login"><span class="btn d-none d-lg-inline me-md-3 text-white">Login</span></Link>
            <Link to="/signup"><Button variant="tertiary"><i class="fas fa-user-plus me-2"></i> Register</Button></Link>
          </>
        }
        <button class="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        {/* <Button
          className="navbar-toggler ms-2"
          type="button"
          ariaLabel="Toggle navigation"
          data-bs-toggle="collapse"
          data-bs-target="#navbar_global"
          aria-controls="navbar_global"
          aria-expanded="false"
        >
          <span className="navbar-toggler-icon"></span>
        </Button> */}
      </div>
    </div>
    </nav>
  </HeaderStyle>
  );
};

export default Header;