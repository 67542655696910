import styled from 'styled-components';

const HomeStyle = styled.div`
  .upload {
    background: rgba(58, 170, 58, 0.1);
    border: 2px dashed #3AAA3A;
    border-radius: 16px;
  }
  .btn-tertiary {
    background-color: #3AAA3A !important;
    border-color: #3AAA3A;
  }
`;

export default HomeStyle;