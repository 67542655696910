import React from 'react';
import Layout from 'components/layout/Layout';
import Home from 'pages/Home';
import Pricing from 'pages/Pricing';
import Auth from 'pages/auth';
import Login from 'pages/auth/Login';
import Signup from 'pages/auth/Signup';
import Create from 'pages/Create';
import List from 'pages/List';

const routes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '/', element: <Home />, index: true },
      { path: '/pricing', element: <Pricing />, index: true },
      { path: '/create', element: <Create />, index: true },
      { path: '/list', element: <List />, index: true },
    ]
  }, {
    path: '/',
    element: <Auth />,
    children: [
      { path: '/login', element: <Login />, index: true },
      { path: '/signup', element: <Signup />, index: true },
    ]
  }
];

export default routes;