import axios from 'axios';
import { api_url } from 'configs/global';
import { store } from '../redux/store';
import { clearUser } from '../redux/slices/auth';
import { showNotification } from './notification';

const http = axios.create({
  baseURL: api_url,
  timeout: 30000,
});

// Config
const TOKEN_PAYLOAD_KEY = 'authorization';
const AUTH_TOKEN = 'token';
const AUTH_TOKEN_TYPE = 'Bearer';

// API Request interceptor
http.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem(AUTH_TOKEN);

    if (access_token) {
      config.headers[TOKEN_PAYLOAD_KEY] = AUTH_TOKEN_TYPE + ' ' + access_token;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    showNotification("erorr", "Error")
    Promise.reject(error);
  }
);

// API respone interceptor
http.interceptors.response.use(
  (response) => {
    // showNotification("success", response.data.message);
    return response.data;
  },
  (error) => {
    console.log(error);
    let notificationParam = {
      message: error.response?.data?.message,
    };

    // Remove token and redirect
    if (error.response?.status === 403 || error.response?.status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      store.dispatch(clearUser());
    }

    if (error.response?.status === 508) {
      notificationParam.message = error.response?.data?.message;
    }

    if (error.response?.status === 500) {
      notificationParam.message = error.response?.data;
    }

    if (error.response?.data?.params) {
      if (Object.values(error.response?.data?.params)[0]){
        notificationParam.message = Object.values(error.response?.data?.params)[0].at(0)
      }
    }

    showNotification("error", notificationParam.message);
    // return Promise.reject(error);
  }
);

export default http;
