import React from 'react';
import HomeStyle from 'scss/custom/pages/Home.style';
import { Outlet } from 'react-router-dom';

const Auth = () => {

  return (
    <HomeStyle>
      <main>
        <section class="min-vh-100 d-flex align-items-center section-image overlay-soft-dark" style={{ background: "url(/pages/form-image.jpg)"}}>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </HomeStyle>
  );
};

export default Auth;