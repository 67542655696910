import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HomeStyle from 'scss/custom/pages/Home.style';
import Button from 'components/widget/Button';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setUserData } from '../redux/slices/auth';

import paymentService from 'services/payment';
import { showNotification } from 'utils/notification';

const Pricing = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    if (sessionId) {
      paymentService.retrive(sessionId).then(res => {
        if (res.payment_status == "paid") {
          showNotification("success", "Payment successful!");
          dispatch(setUserData(res.user));
        } else if (res.payment_status == "unpaid") {
          showNotification("warning", "Payment is not successful.");
        } else {
          showNotification("error", "Action failed.");
        }
        navigate('/pricing');
      });
    }
  }, [])

  const checkoutPricing = async ()=>{
    try {
      if (!user) {
        showNotification("warning", "Plesae login first.");
        navigate("/login");
      } else if (user.status == 2) {
        navigate("/");
      } else {
        const {checkout} = await paymentService.checkout()
        window.location.href = checkout
      }
    } catch (error) {
      alert('Error')
    }

  }

  return (
    <HomeStyle>
      <main>
        <section class="section section-header pb-10 bg-primary text-white">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8 col-xl-8 text-center">
                <h1 class="display-2 fw-light mb-4">
                  Our <span class="fw-bold">Pricing</span>
                </h1>
                <p class="lead">Like our products, our pricing isn’t one-size-fits-all. We calculate your personalized quote by looking at your specific needs.</p>
              </div>
            </div>
          </div>
        </section>
        <div class="section section-lg pt-0">
          <div class="container">
            <div class="row mt-n9">
              <div class="col-12 col-md-6 col-lg-6 mb-5 mb-lg-0">
                <div class="card shadow border-gray-300 text-center py-4">
                  <div class="card-header border-0 bg-white p-3">
                    <h2 class="h3 text-primary mb-4">Starter</h2>
                    <span class="d-block">
                      <span class="display-2 text-dark fw-bold">
                        Free
                      </span>
                      <span class="d-block text-gray font-small">/ ~</span>
                    </span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled mb-4">
                      <li class="list-item pb-3">
                        <strong>10</strong>
                        free credits
                      </li>
                      <li class="list-item pb-3">
                        <strong>0</strong>
                        paid credits
                      </li>
                    </ul>
                    <Link to="/">
                      <div class="d-grid">
                        <a class={user?.status != 2 ? "btn btn-tertiary" : "btn btn-primary"}>{user?.status != 2 ? "Get Started" : "Cancel Pro"}</a>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="card shadow border-gray-300 text-center py-4">
                  <div class="card-header border-0 bg-white p-3">
                    <h2 class="h3 text-primary mb-4">Pro</h2>
                    <span class="d-block">
                      <span class="display-2 text-dark fw-bold">
                        <span class="align-top font-medium">$</span>
                        10 
                      </span>
                      <span class="d-block text-gray font-small">/ month</span>
                    </span>
                  </div>
                  <div class="card-body">
                    <ul class="list-unstyled mb-4">
                      <li class="list-item pb-3">
                        <strong>10</strong>
                        free credits
                      </li>
                      <li class="list-item pb-3">
                        <strong>100</strong>
                        paid credits
                      </li>
                    </ul>
                    <div class="d-grid">
                      <Button onClick={checkoutPricing} variant={user?.status == 2 ? "btn btn-tertiary" : "btn btn-primary"} >{user?.status == 2 ? "Get Started" : "Buy Pro"}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </HomeStyle>
  );
};

export default Pricing;