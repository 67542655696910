import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  user: null,
  filepath: null,
  vin: null,
  ex_color: null,
  in_color: null,
  mileage: null,
  year: null,
  model: null,
  make: null,
  trim: null,
  class: null,
  ads: null,
  status: null
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistoryData(state, action) {
      const { payload } = action;
      state.history = payload;
    },
    updateHistory(state, action) {
      const { payload } = action;
      state.history = {
        ...state.history,
        ...payload
      };
    },
    clearHistory(state) {
      state.history = null;
    },
  },
});

export const { setHistoryData, updateHistory, clearHistory } = historySlice.actions;
export default historySlice.reducer;
