import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'vendor/@fortawesome/fontawesome-free/css/all.min.css';

const Button = ({
  type = 'button',
  variant = 'primary',
  size = 'md',
  iconLeft,
  iconRight,
  iconOnly,
  children,
  ariaLabel,
  ...props
}) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${variant}`]: variant,
    [`btn-${size}`]: size !== 'md',
    'btn-icon-only': iconOnly,
  });

  return (
    <button
      type={type}
      className={buttonClass}
      aria-label={ariaLabel}
      {...props}
    >
      {iconLeft && <span className="me-1"><span className={`fas fa-${iconLeft}`}></span></span>}
      {children}
      {iconRight && <span className="ms-1"><span className={`fas fa-${iconRight}`}></span></span>}
      {iconOnly && <span aria-hidden="true" className={`fas fa-${iconOnly}`}></span>}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconOnly: PropTypes.string,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
};

export default Button;