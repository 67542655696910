import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setHistoryData } from '../../redux/slices/history';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';

import { showNotification } from 'utils/notification';
import UploadStyle from 'scss/custom/components/widget/Upload.style';
import pictureService from 'services/picture';
import Modal from './Modal';

const Upload = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState([]);
  
  const checkSession = () => {
    if (user && user.fcredits == 0 && user.pcredits == 0) {
      showNotification("warning", "Please charge the credits.");
      return false;
    } else {
      return true;
    }
  };

  const initFileList = () => {
    setFormData([]);
    setUploading(false);
  }

  const handleUpload = async (info) => {
    if (!checkSession()) {
      setUploading(false);
      return;
    }

    const form = new FormData();
    form.append('file', info.file);
    setFormData(prevFormData => [...prevFormData, form]);

    const { width, height } = await checkDimension(info.file);

    if (width < 512 || height < 512) {
      setShowModal(true);
    } else {
      handleAgree();
    }
  }
  
  const handleAgree = async () => {
    setShowModal(false);
    try {
      const uploadPromises = formData.map(form => pictureService.upload(form));
      const uploadResults = await Promise.all(uploadPromises);

      const uploadedFileList = uploadResults
        .filter(res => res && res.file)
        .map(res => res.file);

      if (uploadedFileList.length > 0) {
        const extractResult = await pictureService.extract({ filePath: uploadedFileList });

        if (extractResult.status === 200) {
          showNotification("success", 
            (<>
              <div>VIN: {extractResult?.data?.vin || ""}</div>
              <div>Mileage: {extractResult?.data?.mileage || ""}</div>
              <div>ExteriorColor: {extractResult?.data?.ex_color || ""}</div>
              <div>InteriorColor: {extractResult?.data?.in_color || ""}</div>
            </>)
          );

          if (extractResult?.data?.vin === "") {
            showNotification("warning", "Can't extract the VIN from uploaded pictures.");
          } else {
            dispatch(setHistoryData(extractResult?.data));
            navigate('/create');
          }
        }
      }
    } catch (err) {
      console.log(err);
      initFileList();
    } finally {
      initFileList();
    }
  }

  const handleCancel = () => {
    setShowModal(false);
    initFileList();
  }

  const checkDimension = (binaryData) => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([binaryData], { type: 'image/jpeg' }); 
      const url = URL.createObjectURL(blob);
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        URL.revokeObjectURL(url);
        console.log(width, height);
        resolve({ width, height });
      };
  
      img.onerror = (error) => {
        URL.revokeObjectURL(url);
        reject(error);
      };
  
      img.src = url;
    });
  }

  const beforeUpload = (file) => {
    setUploading(true);
    const isPNG = file.type === 'image/png';
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    if (isPNG || isJPEG || isJPG) {
      // toast.error(`${file.name} is not valid file`);
      return false;
    } else {
      setUploading(false);
    }
  };

  return (<>
    <UploadStyle uploading={uploading} page={props?.page}>
      <div class={props.page == "home" ? "row col-8 mx-auto upload" : "upload"}>
        <Dragger
          name='file'
          className={props.page == "create" ? "customSize" : ""}
          multiple={true}
          disabled={uploading}
          maxCount={7}
          onChange={handleUpload}
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <div className="ant-upload-drag-icon" class="mt-3 mb-2">
            {
              uploading ? (
                <Spin />
              ) : (
                props.page == "create" ?
                  <i class={"fas fa-cloud-upload-alt text-black"} style={{fontSize: "1.25rem"}}></i> :
                  <i class={"fas fa-cloud-upload-alt text-white"} style={{fontSize: "1.25rem"}}></i>
              )
            }
          </div>
          <div class="mx-auto text-white d-flex justify-content-center">
            {
              props.page == "create" ? 
                <p class="text-info">{uploading ? "Uploading" : "Upload vehicle pictures and vehicle details will be auto filled"}</p> :
                <p class="col-8 text-white">{uploading ? "Uploading" : "Drag drop image of a vehicle here or click here to browse"}</p>
            }
          </div>
        </Dragger>
      </div>
    </UploadStyle>
    <Modal
      showModal={showModal}
      handleAgree={() => handleAgree()}
      handleCancel={() => handleCancel()}
    />
  </>);
}

export default Upload;