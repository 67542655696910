import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { updateHistory } from '../redux/slices/history';
import { setUserData } from '../redux/slices/auth';

import HomeStyle from 'scss/custom/pages/Home.style';
import pictureService from 'services/picture';
import Upload from 'components/widget/Upload';
import { showNotification } from 'utils/notification';

const Create = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { history } = useSelector((state) => state.history, shallowEqual);
  const [carInfo, setCarInfo] = useState({});
  const [valid, setValid] = useState({
    vin: false,
    year: false,
    make: false,
    model: false,
    ex_color: false,
    in_color: false,
    mileage: false
  });
  const [focusState, setFocusState] = useState({
    vin: false,
    year: false,
    make: false,
    model: false,
    ex_color: false,
    in_color: false,
    mileage: false
  });
  const [generateAdLoading, setGenerateAdLoading] = useState(false);
  const [extractLoading, setExtracLoading] = useState(false);

  const handleGenerate = () => {
    setGenerateAdLoading(true);
    pictureService.generateAd(carInfo).then((info) => {
      dispatch(updateHistory(info.data));
      dispatch(setUserData(info.user));
      showNotification("success", "Generating ad successful")
      setGenerateAdLoading(false);
      navigate('/list');
    });
  };

  const handleExtract = () => {
    if (extractLoading) return;
    setExtracLoading(true);
    pictureService.getCarInfoByVin(carInfo.id, carInfo.vin).then((info) => {
      if (info) {
        setCarInfo({...info});
        dispatch(updateHistory(info));
      }
      setExtracLoading(false);
    });
  };

  const fields = ([
    { name: "year", placeholder: "Year" },
    { name: "make", placeholder: "Make" },
    { name: "model", placeholder: "Model" },
    { name: "ex_color", placeholder: "Exterior Color" },
    { name: "in_color", placeholder: "Interior Color" },
    { name: "mileage", placeholder: "Mileage" }
  ]);

  const anyFocusStateFalse = () => {
    return Object.values(valid).some(item => item === false);
  };

  const handleFocus = (key, state) => {
    if(Object.keys(focusState).indexOf(key) == -1) return;
    setFocusState({...focusState, [key]: state });
  };

  const validationFields = (key) => {
    if (!carInfo.hasOwnProperty(key) || carInfo[key].length === 0) return false;
    if (key === "vin") {
      const vin = carInfo.vin;
      if (vin.length !== 17 || /[IOQ]/.test(vin)) return false;
    }
    if (key === "year" && carInfo.year.length !== 4) return false;
    return true;
  }

  useEffect(() => {
    setValid(Object.keys(valid).reduce((acc, key) => {
      acc[key] = validationFields(key);
      return acc;
    }, {}));
  }, [carInfo]);

  useEffect(() => {
    console.log(history);
    if (history) setCarInfo({...history});
  }, [history]);

  return (
    <HomeStyle>
      <main>
        <section class="section section-lg pt-7 bg-gray-200">
          <div class="container">
            <div class="col-12 col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-text-light breadcrumb-primary text-white">
                  <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">Add Listing</li>
                </ol>
              </nav>

              <div class="card border-gray-300 mb-4">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="d-flex align-items-center h-100">
                        <Upload page="create" />
                      </div>
                    </div>
                    <div class="col-md-1 d-flex p-4 align-items-center justify-content-center">
                      <span class="fas fa-chevron-right" />
                    </div>
                    <div class="col-md-8">
                      <p class="text-info mb-3">Or Enter vehicle details manually</p>

                      <div class="row mb-2">
                        <div class="col-md-9 mb-2">
                          <input type="text"
                            class={history ? (valid.vin ? "form-control is-valid" : "form-control is-invalid") : "form-control"} 
                            placeholder="VIN Number" value={carInfo.vin} onChange={(e) => setCarInfo({...carInfo, vin: e.target.value})} 
                            onClick={() => handleFocus("vin", true)} onBlur={() => handleFocus("vin", false)} />
                          {
                            valid.vin ? 
                              <div class={focusState.vin ? "valid-feedback visible" : "valid-feedback invisible"}>Trust but verify!</div> :
                              <div class={focusState.vin ? "invalid-feedback visible" : "invalid-feedback invisible"}>Problem extracting. Enter manually.</div>
                          }
                        </div>
                        <div class="col-md-3 mb-2">
                          <button class="btn btn-primary w-100" type="button" disabled={extractLoading || generateAdLoading || !validationFields('vin') || !carInfo.vin || carInfo.vin.trim() === "" ? "disabled" : ""} onClick={() => handleExtract()}>
                            <span class={extractLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            <span class="ms-1">{extractLoading ? "Extracting" : "Extract info"}</span>
                          </button>
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-md-9">
                          <div class="row">
                            {fields.map((field, index) => index < 3 ? (
                              <div class="col-md-4 mb-2" key={index}>
                                <input type="text"
                                  class={history ? (valid[field.name] ? "form-control is-valid" : "form-control is-invalid") : "form-control"} 
                                  placeholder={field.placeholder} value={carInfo[field.name]} onChange={(e) => setCarInfo({...carInfo, [field.name]: e.target.value})}
                                  onClick={() => handleFocus(field.name, true)} onBlur={() => handleFocus(field.name, false)} />
                                {
                                  valid[field.name] ? 
                                    <div class={focusState[field.name] ? "valid-feedback visible" : "valid-feedback invisible"}>Trust but verify!</div> :
                                    <div class={focusState[field.name] ? "invalid-feedback visible" : "invalid-feedback invisible"}>Problem extracting. Enter manually.</div>
                                }
                              </div>
                            ) : "")}
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-9">
                          <div class="row">
                            {fields.map((field, index) => index > 2 ? (
                              <div class="col-md-4 mb-2" key={index}>
                                <input type="text"
                                  class={history ? (valid[field.name] ? "form-control is-valid" : "form-control is-invalid") : "form-control"} 
                                  placeholder={field.placeholder} value={carInfo[field.name]} onChange={(e) => setCarInfo({...carInfo, [field.name]: e.target.value})}
                                  onClick={() => handleFocus(field.name, true)} onBlur={() => handleFocus(field.name, false)} />
                                {
                                  valid[field.name] ? 
                                    <div class={focusState[field.name] ? "valid-feedback visible" : "valid-feedback invisible"}>Trust but verify!</div> :
                                    <div class={focusState[field.name] ? "invalid-feedback visible" : "invalid-feedback invisible"}>Problem extracting. Enter manually.</div>
                                }
                              </div>
                            ) : "")}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <button class="btn btn-primary w-100 mb-2" type="button" disabled={generateAdLoading || anyFocusStateFalse() ? "disabled" : ""} onClick={() => handleGenerate()}>
                            <span class={generateAdLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            <span class="ms-1">{generateAdLoading ? "Generating" : "Generate Listing"}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </HomeStyle>
  );
};

export default Create;