import React from 'react';
import Button from 'components/widget/Button';
import FooterStyle from 'scss/custom/components/layout/Footer.style';

const Footer = () => {
  return (<FooterStyle>
    <footer class="footer pt-6 pb-5 bg-primary text-white">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img class="navbar-brand-dark mb-3 mt-n4" width="86" height="70" src="/logo/logo.png" alt="Logo light" />
            <p>Pictoad is a  service that transforms images into captivating ads, helping you create beautiful, creative, and modern marketing campaigns effortlessly.</p>
            <ul class="social-buttons mb-5 mb-lg-0">
              <li>
                <a href="https://twitter.com/themesberg" aria-label="twitter social link" class="icon-white me-2">
                  <span class="fab fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/themesberg/" class="icon-white me-2" aria-label="facebook social link">
                  <span class="fab fa-facebook"></span>
                </a>
              </li>
              <li>
                <a href="https://github.com/themesberg" aria-label="github social link" class="icon-white me-2">
                  <span class="fab fa-github"></span>
                </a>
              </li>
              <li>
                <a href="https://dribbble.com/themesberg" class="icon-white" aria-label="dribbble social link">
                  <span class="fab fa-dribbble"></span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-2 mb-5 mb-lg-0">
            <span class="h5">PicToAd</span>
            <ul class="footer-links mt-2">
              <li>
                <a target="_blank" href="https://themesberg.com/blog">Try it</a>
              </li>
              <li>
                <a target="_blank" href="https://themesberg.com/themes">Products</a>
              </li>
              <li>
                <a target="_blank" href="https://themesberg.com/about">API</a>
              </li>
              <li>
                <a target="_blank" href="https://themesberg.com/contact">Contact Us</a>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-2 mb-5 mb-lg-0">
            <span class="h5">Other</span>
            <ul class="footer-links mt-2">
              <li>
                <a href="https://themesberg.com/docs/bootstrap-5/pixel/getting-started/quick-start/" target="_blank">
                  Docs <span class="badge badge-sm bg-success ms-2">v5.4</span>
                </a>
              </li>
              <li>
                <a href="https://themesberg.com/docs/pixel-bootstrap/getting-started/changelog/" target="_blank">Changelog</a>
              </li>
              <li>
                <a target="_blank" href="https://themesberg.com/licensing">Pricing</a>
              </li>
              <li>
                <a target="_blank" href="https://github.com/themesberg/pixel-bootstrap-ui-kit/issues">Support</a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4 mb-5 mb-lg-0">
            <span class="h5">Subscribe</span>
            <p class="text-muted font-small mt-2">Join our mailing list. We write rarely, but only the best content.</p>
            <form action="#">
              <div class="form-row mb-2">
                <div class="col-12">
                  <label class="h6 fw-normal text-muted" for="exampleInputEmail3">Email address</label>
                  <input type="email" class="form-control mb-2" placeholder="example@company.com" name="email" aria-label="Subscribe form" id="exampleInputEmail3" required />
                </div>
                <div class="col-12 d-grid">
                  <Button type="submit" variant="tertiary" data-loading-text="Sending"><span>Subscribe</span></Button>
                </div>
              </div>
            </form>
            <p class="text-muted font-small m-0">
              We’ll never share your details. See our <a class="text-white" href="#">Privacy Policy</a>
            </p>
          </div>
        </div>
        <hr class="bg-secondary my-3 my-lg-5" />
        <div class="row">
          <div class="col mb-md-0">
            <a href="https://themesberg.com" target="_blank" class="d-flex justify-content-center mb-3">
              <img src="/logo/logo.png" width="64" height="52" class="me-2 mt-n4" alt="Themesberg Logo" />
              <p class="text-white fw-bold footer-logo-text m-0">PicToAd</p>
            </a>
            <div class="d-flex text-center justify-content-center align-items-center" role="contentinfo">
              <p class="fw-normal font-small mb-0">
                Copyright © PicToAd 2019-<span class="current-year">2024</span>
                . All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </FooterStyle>);
};

export default Footer;