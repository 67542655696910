import server from 'utils/server';

const pictureService = {
  upload: (data) => server.post('upload', data),
  extract: (data) => server.post('tag/extract', data),
  carInfo: (id) => server.get(`tag/carInfo?id=${id}`),
  generateAd: (data) => server.post('tag/generateAd', data),
  getCarInfoByVin: (id, vin) => server.get(`tag/carInfoByVin?id=${id}&vin=${vin}`)
};

export default pictureService;
