import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeStyle from 'scss/custom/pages/Home.style';
import { useSelector, shallowEqual } from 'react-redux';
import { clearHistory } from '../redux/slices/history';
import { useDispatch } from 'react-redux';

const List = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { history } = useSelector((state) => state.history, shallowEqual);

  const handleMoveToCreate = () => {
    dispatch(clearHistory());
    navigate('/create');
  }

  return (
    <HomeStyle>
      <main>
        <section class="section section-lg pt-7 bg-gray-200">
          <div class="container">
            <div class="col-12 col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-text-light breadcrumb-primary text-white">
                  <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">Listing</li>
                </ol>
              </nav>

              <div class="nav nav-pills nav-item mb-3 d-flex">
                <a class="nav-link mb-sm-3 mb-md-0 active ms-auto" onClick={() => handleMoveToCreate()}>
                  <span class="fas fa-plus me-2"></span>Create New Listing
                </a>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="card border-gray-300 mb-4">
                    <div class="card-body my-4">
                      <label class="mb-4 me-4">Share Ad</label>
                      <button class="btn btn-icon-only btn-facebook m-1" type="button" aria-label="facebook button" title="facebook button"><span aria-hidden="true" class="fab fa-facebook"></span></button>
                      <button class="btn btn-icon-only btn-twitter m-1 text-white" type="button" aria-label="pinterest button" title="pinterest button"><span aria-hidden="true" class="fab fa-twitter"></span></button>
                      <button class="btn btn-icon-only btn-instagram m-1" type="button" aria-label="youtube button" title="youtube button"><span aria-hidden="true" class="fab fa-instagram"></span></button>
                      <button class="btn btn-icon-only btn-dribbble m-1" type="button" aria-label="github button" title="github button"><span aria-hidden="true" class="fab fa-dribbble"></span></button>
                      <button class="btn btn-icon-only btn-slack m-1 text-white" type="button" aria-label="twitch button" title="twitch button"><span aria-hidden="true" class="fab fa-slack"></span></button>
                      <button class="btn btn-icon-only btn-dropbox m-1" type="button" aria-label="paypal button" title="paypal button"><span aria-hidden="true" class="fab fa-dropbox"></span></button>
                      <button class="btn btn-icon-only btn-github m-1" type="button" aria-label="behance button" title="behance button"><span aria-hidden="true" class="fab fa-github"></span></button>

                      <span class="position-absolute bg-primary p-3 rounded-start end-0">
                        <span class="fas fa-cloud-download-alt me-3 text-success"></span>
                        <span class="text-white">Download</span>
                      </span>

                      <div dangerouslySetInnerHTML={{ __html: history?.ads }} />
                    </div>
                  </div>

                  <div class="card border-gray-300 mb-4">
                    <div class="card-body">
                      <div class="row">
                        <label class="form-label">Vehicle Details</label>
                      </div>
                      <div class="row">
                        <div class="col-md-4 mb-3">
                          <table class="table table-hover">
                            <tbody>
                              <tr>
                                <th scope="row" id="ModelYear3" headers="class3">Year</th>
                                <td headers="ModelYear3">{history?.year}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="Make3" headers="class3">Make</th>
                                <td headers="Make3">{history?.make}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="Model3" headers="class3">Model</th>
                                <td headers="Model3">{history?.model}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="Model3" headers="class3">Trim</th>
                                <td headers="Model3">{history?.trim}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="Model3" headers="class3">Class</th>
                                <td headers="Model3">{history?.class}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="BodyClass3" headers="class3">Exterior Color</th>
                                <td headers="BodyClass3">{history?.ex_color}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="BodyClass3" headers="class3">Interior Color</th>
                                <td headers="BodyClass3">{history?.in_color}</td>
                              </tr>
                              <tr>
                                <th scope="row" id="BodyClass3" headers="class3">Miles</th>
                                <td headers="BodyClass3">{history?.mileage}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-8 mb-3">
                          <div class="row align-items-center">
                            {
                              history?.filepath.split(',').map((item) => {
                                return (
                                  <div class="col-md-6 col-lg-6 text-center my-2">
                                    <img src={`/${item}`} class="card-img-top" alt="Google office" />
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card border-gray-300 mb-4">
                    <div class="card-body">
                      <h2 class="h3 mb-2">DexCar Auto Sales</h2>
                      <p class="mb-2">(407) 974-6827</p>
                      <p class="mb-0">Orlando, FL</p>
                    </div>
                  </div>

                  <div class="card border-gray-300 mb-4">
                    <div class="card-body">
                      <h2 class="h3 mb-3">Contact Seller</h2>
                      <div class="mb-4">
                        <label for="disabledTextInput">Your Name</label>
                        <div class="input-group">
                          <span class="input-group-text" id="basic-addon1">
                            <span class="fas fa-user"></span>
                          </span>
                          <input type="text" class="form-control" id="exampleInputIconLeft" aria-label="Search" aria-describedby="basic-addon1" />
                        </div>
                      </div>
                      <div class="mb-4">
                        <label for="disabledTextInput">Your Email</label>
                        <div class="input-group">
                          <span class="input-group-text" id="basic-addon1">
                            <span class="fas fa-envelope"></span>
                          </span>
                          <input type="text" class="form-control" id="exampleInputIconLeft" aria-label="Search" aria-describedby="basic-addon1" />
                        </div>
                      </div>
                      <div class="mb-4">
                        <label for="disabledTextInput">Phone Number</label>
                        <div class="input-group">
                          <span class="input-group-text" id="basic-addon1">
                            <span class="fas fa-phone"></span>
                          </span>
                          <input type="text" class="form-control" id="exampleInputIconLeft" aria-label="Search" aria-describedby="basic-addon1" />
                        </div>
                      </div>
                      <div class="mb-4">
                        <label for="disabledTextInput">Your Message</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
                      </div>
                      <a class="btn w-100 btn-primary mb-2  " href="#">Send Message</a>
                    </div>
                  </div>

                  <div class="card border-gray-300 mb-4">
                    <div class="card-body">
                      <h2 class="h3 mb-4">DexCar Auto Sales</h2>
                      <div class="mb-4">
                        <label>Down Payment</label>
                        <input class="form-control" />
                      </div>
                      <div class="mb-4">
                        <label>Length of Loan</label>
                        <div class="progress-wrapper">
                          <div class="d-flex justify-content-center mb-2">
                            <div class=""><span>72 Months</span></div>
                          </div>
                          <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" style={{width: "60%"}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-4">
                        <label>Intrest Rate %</label>
                        <input class="form-control" />
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-6"></div>
                        <div class="col-md-6 d-flex flex-column justify-content-end">
                          <button class="btn btn-tertiary animate-up-2" type="button">Submit</button>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-8">
                          <label class="my-2">Amount to be Financed</label>
                        </div>
                        <div class="col-md-4">
                          <input class="form-control" />
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-8">
                          <label class="my-2">Monthly Payment</label>
                        </div>
                        <div class="col-md-4">
                          <input class="form-control" />
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-8">
                          <label class="my-2">Total Interest Paid</label>
                        </div>
                        <div class="col-md-4">
                          <input class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      </main>
    </HomeStyle>
  );
};

export default List;