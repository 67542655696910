import React from 'react';
import HomeStyle from 'scss/custom/pages/Home.style';
import Upload from 'components/widget/Upload';
import Button from 'components/widget/Button';

const Home = () => {
  return (
    <HomeStyle>
      <main>
        <div class="section section-header pb-7 pb-md-10 bg-primary overflow-hidden">
          <div class="container z-2">
            <div class="row justify-content-center text-center">
              <div class="col-12 col-md-10">
                <div class="mb-3">
                  <h1 class="display-3 font-size-md-down-5 fw-light mb-0 text-white">
                    AI generated <span class="fw-bold" style={{color: '#3AAA3A'}}>Vehicle</span> Listings
                  </h1>
                </div>
                <p class="my-4 px-0 px-lg-7 lead text-white">PicToAd turns your images into AI-generated captivating ads, making it effortless to create relevant, creative, and highly effective listings.</p>
                <div class="col-12 col-md-10 text-center m-auto">
                  <Upload page="home" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="section section-md pt-0">
          <div class="container">
            <div class="col-12 col-lg-12">
              <div class="row justify-content-center mt-n7">
                <div class="col-12 col-md-6">
                  <div class="row mt-5 mt-lg-0 px-3">
                    <div class="card shadow border-gray-300">
                      <div class="card-body p-4 p-lg-5">
                        <img src="/pages/design.svg" alt="Web design illustration" class="image-md mb-4" />
                        <h2 class="h4 mb-2">VIN to Ad</h2>
                        <p>Type or upload your VIN images to generate an SEO-optimized full ad listing, saving you hours of work every month.</p>
                        <a href="#" class="btn btn-sm btn-primary">Learn more</a>
                      </div>
                    </div>
                    <div class="card shadow border-gray-300 mt-3">
                      <div class="card-body p-4 p-lg-5">
                        <img src="/pages/technologies.svg" alt="Responsive illustration" class="image-md mb-4" />
                        <h2 class="h4 mb-2">AI does it better</h2>
                        <p>Extracting VIN from vehicle images, mileage from odometers, color, materials, full listing titles and body, and more...in seconds</p>
                        <a href="#" class="btn btn-sm btn-primary">Learn more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-lg-5">
                  <div class="row mt-3 mt-lg-0 px-3">
                    <div class="card shadow border-gray-300">
                      <div class="card-body p-4 p-lg-5">
                        <img src="/pages/code.svg" alt="Code illustration" class="image-md mb-4" />
                        <h2 class="h4 mb-2">API Ready</h2>
                        <p>Integrate any vehicle listing service with our REST API to get vehicle details and listings on the fly</p> <br/>
                        <a href="#" class="btn btn-sm btn-primary">Learn more</a>
                      </div>
                    </div>
                    <div class="card shadow border-gray-300 mt-3">
                      <div class="card-body p-4 p-lg-5">
                        <img src="/pages/support.svg" alt="Support illustration" class="image-md mb-4" />
                        <h2 class="h4 mb-2">Easy Integrations</h2>
                        <p>Continue using your existing solutions. We just make it easier and faster with our integrations into your existing tools.</p>
                        <a href="#" class="btn btn-sm btn-primary">Learn more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="section section-md bg-gray-200">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="glide-testimonials">
                  <div data-glide-el="track" class="glide__track">
                    <ul class="glide__slides">
                      <li class="glide__slide text-center">
                        <div class="mb-3">
                          <img class="m-auto avatar-lg p-2 border-gray-300 border" src="team/profile-picture-1.jpg" alt="Neil Avatar" />
                        </div>
                        <span class="d-block my-3">
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star far fa-star text-gray"></span>
                        </span>
                        <blockquote class="blockquote bg-gray-200">
                          Themesberg makes beautiful products to help people with creative ideas succeed. Our company empowers millions of people.<footer class="blockquote-footer mt-3 text-primary">Neil Sims</footer>
                        </blockquote>
                      </li>
                      <li class="glide__slide text-center">
                        <div class="mb-3">
                          <img class="m-auto avatar-lg p-2 border-gray-300 border rounded-circle" src="team/profile-picture-3.jpg" alt="Bonnie Avatar" />
                        </div>
                        <span class="d-block my-3">
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                        </span>
                        <blockquote class="blockquote bg-gray-200">
                          Themesberg makes beautiful products to help people with creative ideas succeed. Our company empowers millions of people.<footer class="blockquote-footer mt-3 text-primary">Bonnie Green</footer>
                        </blockquote>
                      </li>
                      <li class="glide__slide text-center">
                        <div class="mb-3">
                          <img class="m-auto avatar-lg p-2 border-gray-300 border rounded-circle" src="team/profile-picture-2.jpg" alt="Christopher Avatar" />
                        </div>
                        <span class="d-block my-3">
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                          <span class="star fas fa-star text-warning"></span>
                        </span>
                        <blockquote class="blockquote bg-gray-200">
                          Themesberg makes beautiful products to help people with creative ideas succeed. Our company empowers millions of people.<footer class="blockquote-footer mt-3 text-primary">Christopher Wood</footer>
                        </blockquote>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section section-sm py-0 mt-5 mt-md-n5 d-none d-sm-block">
          <div class="container top-0 right-o left-0 z-2">
            <div class="row justify-content-center">
              <div class="col">
                <div class="bg-white border border-gray-300 rounded-pill py-4">
                  <ul class="d-flex flex-wrap justify-content-center list-unstyled mb-0">
                    <li class="mx-xl-4 mx-3 d-flex align-items-center">
                      <h2 class="h5 m-0 mb-4 mb-md-0 text-gray">Pixel partners</h2>
                    </li>
                    <li class="mx-xl-4 mx-3 mb-3 mb-md-0">
                      <img class="img-fluid image-sm" src="clients/paypal.svg" alt="paypal logo" />
                    </li>
                    <li class="mx-xl-4 mx-3 mb-3 mb-md-0">
                      <img class="img-fluid image-sm" src="clients/airbnb.svg" alt="airbnb logo" />
                    </li>
                    <li class="mx-xl-4 mx-3 mb-3 mb-md-0">
                      <img class="img-fluid image-sm" src="clients/forbes.svg" alt="forbes logo" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="section">
          <div class="container">
            <div class="row justify-content-center">
              <form action="#" class="col-md-8">
                <div class="text-center">
                  <h2 class="h1 fw-light">
                    Stay in the know
                  </h2>
                  <p class="lead mb-5">Get front row seat to innovation. Join our Newsletter.</p>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input type="email" class="form-control form-control-lg rounded-start" placeholder="Your email" required />
                    <Button type="submit" variant="tertiary">Subscribe</Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

    </HomeStyle>
  );
};

export default Home;