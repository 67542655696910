import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserData } from '../../redux/slices/auth';
import HomeStyle from 'scss/custom/pages/Home.style';
import Button from 'components/widget/Button';
import authService from 'services/auth';
import { Link } from 'react-router-dom';
import { showNotification } from 'utils/notification';

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [agree, setAgree] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [signupLoading, setSignupLoading] = useState(false);

  const handleSubmit = () => {
    if (signupLoading) {
      showNotification("warning", "This action is loading.");
    } else if (email == "") {
      showNotification("warning", "Email is empty.");
    } else if (password == "") {
      showNotification("warning", "Password is empty.");
    } else if (repassword == "") {
      showNotification("warning", "Confirm Password is empty.");
    } else if(password !== repassword) {
      showNotification("warning", "Password is not confirmed.");
    } else if(!agree) {
      showNotification("warning", "Please agree to the terms and conditions.");
    } else {
      setSignupLoading(true);
      authService.signup({ email, password }).then((data) => {
        if (data?.status == 201) {
          localStorage.setItem('token', data?.token);
          dispatch(setUserData(data?.user));
          navigate('/');
          showNotification("success", data.message || "Sgin up successful!");
        }
      });
      setSignupLoading(false);
    }
  };

  return (
    <HomeStyle>
      <div class="text-center text-md-center mb-4 mt-md-0">
        <h1 class="mb-0 h3">Create an account</h1>
      </div>
      <form action="#">
        <div class="form-group mb-4">
          <label for="email">Your Email</label>
          <div class="input-group">
            <span class="input-group-text" id="basic-addon3"><span class="fas fa-envelope"></span></span>
            <input type="email" class="form-control" placeholder="example@company.com" id="email" required onChange={(e) => setEmail(e.target.value)} />
          </div>  
        </div>
        <div class="form-group">
          <div class="form-group mb-4">
            <label for="password">Your Password</label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon4"><span class="fas fa-unlock-alt"></span></span>
              <input type="password" placeholder="Password" class="form-control" id="password" required onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <div class="form-group mb-4">
            <label for="password_confirm">Confirm Password</label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon5"><span class="fas fa-unlock-alt"></span></span>
              <input type="password" placeholder="Confirm Password" class="form-control" id="password_confirm" required onChange={(e) => setRepassword(e.target.value)} />
            </div>  
          </div>
          <div class="form-check mb-4">
            <input class="form-check-input" type="checkbox" value="" id="terms" onChange={(e) => setAgree(e.target.checked)} checked={agree}/>
            <label class="form-check-label" for="terms">
              I agree to the <a href="#">terms and conditions</a>
            </label>
          </div>
        </div>
        <div class="d-grid">
          <Button variant='tertiary' onClick={() => handleSubmit()} disabled={signupLoading}>Sign up</Button>
        </div>
      </form>
      <div class="mt-3 mb-4 text-center">
        <span class="fw-normal">or</span>
      </div>
      <div class="btn-wrapper my-4 text-center">
        <a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-facebook me-2" aria-label="facebook button" title="facebook button">
          <span aria-hidden="true" class="fab fa-facebook-f"></span>
        </a>
        <a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-twitter me-2" aria-label="twitter button" title="twitter button">
          <span aria-hidden="true" class="fab fa-twitter"></span>
        </a>
        <a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-facebook" aria-label="github button" title="github button">
          <span aria-hidden="true" class="fab fa-github"></span>
        </a>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-4">
        <span class="fw-normal">
          Already have an account?
          <Link to="/login"><span class="fw-bold text-underline">Login here</span></Link>
        </span>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-2">
        <Link to="/"><span class="fw-normal text-underline">Back to page</span></Link>
      </div>
    </HomeStyle>
  );
};

export default Signup;